<template>
    <component :is="template">

    </component>
</template>

<script>
export default {
    computed: {
        template(){
            return () => import(`@/templates/${this.$theme.pages.about}`);
        }
    }
}
</script>